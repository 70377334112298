<template>
  <div>
    <button @click="toggleDeleteModal" class="delete-website__button">
      <img src="../../images/trash-16.svg" alt="trash symbol" class="delete-website__trash-symbol" />
    </button>
    <DgModal
      size="sm"
      :options="{
        title: $t('dg_suite.consent_management.overview.modal.title'),
        closeLabel: $t('dg_suite.consent_management.overview.modal.cancel'),
        submitLabel: $t('dg_suite.consent_management.overview.modal.delete'),
      }"
      :show="showDeleteWebsiteModal"
      @close="toggleDeleteModal"
      @submit="doDeleteWebsite"
    >
      <p v-html="markdown($t('dg_suite.consent_management.overview.modal.description', { url: data.url }))" />
    </DgModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import errors from "../../mixins/errors";

export default {
  props: {
    data: Object,
  },
  mixins: [errors],
  data() {
    return {
      showDeleteWebsiteModal: false,
    };
  },
  methods: {
    ...mapActions(["deleteWebsite"]),
    async doDeleteWebsite() {
      try {
        await this.deleteWebsite(this.data.id);
        this.$dgNotification.show({
          type: "success",
          title: this.$t("dg_suite.consent_management.overview.success_message"),
        });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.toggleDeleteModal();
      }
    },
    toggleDeleteModal() {
      this.showDeleteWebsiteModal = !this.showDeleteWebsiteModal;
    },
  },
};
</script>

<style scoped lang="scss">
.delete-website {
  &__button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  &__trash-symbol {
    padding: 0 14px;
  }
}
</style>
