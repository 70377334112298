<template>
  <StatusIndicator v-bind="statusProps" />
</template>

<script>
import StatusIndicator from "@/engines/common/components/StatusIndicator";

export default {
  props: {
    data: Object,
  },
  components: {
    StatusIndicator,
  },
  computed: {
    status() {
      return this.data.provision ? "active" : "finished";
    },
    label() {
      return this.$t(`dg_suite.consent_management.overview.table.provision.${this.data.provision.toString()}`);
    },
    statusProps() {
      return { label: this.label, status: this.status };
    },
  },
};
</script>
