export default {
  methods: {
    handleError(error) {
      switch (error.status) {
        case 404:
          error.message = this.$t("errors.not_found");
        default:
          this.$dgNotification.show({ type: "error", title: error.message });
      }
    },
  },
};
