<template>
  <div>
    <DgPanel :header="$t('dg_suite.consent_management.overview.title')">
      <div
        v-html="markdown($t('dg_suite.consent_management.overview.welcome', { number_of_websites: numberOfWebsites }))"
      ></div>
      <DgTable class="overview-table" :columns="tableColumns" :rows="tableData" v-if="tableData.length > 0" />
      <div v-else>{{ $t("dg_suite.consent_management.overview.no_websites") }}</div>
      <div v-if="areMoreWebsitesPossible">
        <DgButton
          @click="$router.push({ name: 'CookieManagerConfiguration', params: { website_id: 'new' } })"
          class="overview__new-page-button"
          >{{ $t("dg_suite.consent_management.overview.new_website_button") }}</DgButton
        >
      </div>
      <div v-else>{{ $t("dg_suite.consent_management.overview.maximum_reached") }}</div>
      <p v-if="backendError">{{ backendError }}</p>
    </DgPanel>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Structural } from "@/dgui-customer-components/";
import OverviewProvisionCheck from "../../components/CookieManager/OverviewProvisionCheck";
import DeleteWebsite from "../../components/CookieManager/DeleteWebsite";

import sharedFunctions from "../../mixins/shared-functions";
import config from "../../store/config";

export default {
  name: "CookieManagerOverview",
  mixins: [sharedFunctions],
  components: {
    DgPanel: Structural.DgPanel,
  },
  data() {
    return {
      backendError: null,
      numberOfWebsites: config.NUMBER_OF_ALLOWED_WEBSITES,
      tableColumns: [
        { title: this.$t("dg_suite.consent_management.overview.table.url"), field: "url", width: "75" },
        {
          title: this.$t("dg_suite.consent_management.overview.table.provision.title"),
          field: "provision",
          width: "25",
          render: OverviewProvisionCheck,
        },
        { title: this.$t("dg_suite.consent_management.overview.table.delete"), field: "delete", render: DeleteWebsite },
      ],
    };
  },
  computed: {
    ...mapState({
      websites: state => state.dg_suite.websites || {},
    }),
    tableData() {
      return Object.keys(this.websites).map(id => {
        const website = this.websites[id];
        return {
          url: website.url,
          provision: website.usercentrics_setting_id != null,
          id: website.id,
        };
      });
    },
  },
  methods: {
    ...mapActions(["fetchWebsites"]),
    async doFetchWebsites() {
      this.isLoading = true;

      try {
        await this.fetchWebsites();
      } catch (error) {
        this.backendError = error.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
  async created() {
    await this.doFetchWebsites();
  },
};
</script>

<style scoped>
.overview-table {
  margin: 15px 0;
}

.overview__new-page-button {
  left: calc(100% - 150px);
}
</style>
