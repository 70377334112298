<template>
  <div class="consent-management-main">
    <DgSidebar :menuItems="navigationBar" :title="$t('dg_suite.consent_management.navigation.title')" />
    <main class="dg-main">
      <transition name="fade" mode="out-in" v-if="!isLoading">
        <router-view v-if="isActivated"></router-view>
        <p v-else>{{ $t("dg_suite.consent_management.feature_not_activated") }}</p>
      </transition>
      <p v-else>{{ $t("dg_suite.consent_management.loading") }}</p>
    </main>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import sharedFunctions from "../mixins/shared-functions";

export default {
  name: "CookieManagerLayout",
  mixins: [sharedFunctions],
  data() {
    return {
      isLoading: true,
      navigation: {
        overview: {
          title: this.$t("dg_suite.consent_management.navigation.overview"),
          icon: require("../images/website.svg"),
          href: { name: "CookieManagerOverview" },
        },
        newPage: {
          title: this.$t("dg_suite.consent_management.navigation.new_website"),
          icon: require("../images/circle-plus-24.svg"),
          href: { name: "CookieManagerConfiguration", params: { website_id: "new" } },
        },
      },
    };
  },
  computed: {
    ...mapState({
      sidebarMenuVisibility: state => state.menu.sidebarMenuVisibility,
      websites: state => state.dg_suite.websites,
    }),
    ...mapGetters(["isCustomerFeatureActivated"]),
    isActivated() {
      return this.isCustomerFeatureActivated("cookie_manager");
    },
    navigationWebsiteItems() {
      return Object.keys(this.websites).map(websiteId => {
        const { url } = this.websites[websiteId];

        // This creates a more digestible link to be displayed on the sidebar
        // If the URL is still not available (e.g., while it's saving), it will display "loading.url"
        // Once a valid URL can be found, it will display everything between "http(s)://" and the first "/" after the domain
        // if one can be found. A URL like "https://dg-feature.heroku.com/content/go-to/some/complex/url" will be saved as
        // such, but only displayed as "dg-feature.heroku.com" on the sidebar.
        const formattedUrlToBeDisplayed =
          url && url.includes("/") && url.length > 2 ? url.split("/")[2] : "loading.url";

        return {
          title: formattedUrlToBeDisplayed,
          icon: require("../images/gear-24.svg"),
          children: [
            {
              title: this.$t("dg_suite.consent_management.navigation.configuration"),
              href: { name: "CookieManagerConfiguration", params: { website_id: websiteId } },
            },
            {
              title: this.$t("dg_suite.consent_management.navigation.access"),
              href: { name: "CookieManagerAccess", params: { website_id: websiteId } },
            },
          ],
        };
      });
    },
    navigationBar() {
      let nav = [this.navigation.overview, ...this.navigationWebsiteItems];
      if (this.areMoreWebsitesPossible) {
        return nav.concat(this.navigation.newPage);
      }
      return nav;
    },
  },
  methods: {
    ...mapActions(["fetchWebsites"]),
  },
  async created() {
    try {
      await this.fetchWebsites();
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped lang="scss">
.dg-main {
  overflow: auto;
  margin: 0 auto;
  width: 1140px;

  > div {
    width: 100%;
    margin: 0 auto;
  }
}

.consent-management-main {
  display: flex;
}
</style>
